import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import httpBuildQuery from 'http-build-query'

dayjs.extend(customParseFormat)
const urlAPI = process.env.VUE_APP_APIENDPOINT

export const ReportService = {
  /**
   * /api/v2/report/seller/{idEmployee}/month/{year}/{month}
   *
   * @param idCustomer
   * @param period
   * @returns {Promise<any>}
   */
  getSellerMonthly (idEmployee, year, month) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/report/seller/${idEmployee}/month/${year}/${month}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * /api/v2/report/seller/{idEmployee}/month/{year}/{quarter}
   *
   * @param idCustomer
   * @param period
   * @returns {Promise<any>}
   */
  getSellerQuarter (idEmployee, filter) {
    let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/report/seller?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * /api/v2/report/seller-new/{idEmployee}/month/{year}/{quarter}
   *
   * @param idCustomer
   * @param period
   * @returns {Promise<any>}
   */
  getSellerQuarterNew (idEmployee, filter) {
    let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/report/seller/new?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * /api/v2/report/seller/audit
   *
   * @param idEmployee
   * @param filter
   * @returns {Promise<any>}
   */
  getSellerAudit (idEmployee, filter) {
    let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/report/seller/audit?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * /api/v2/report/portal-access
   *
   * @param idEmployee
   * @param filter
   * @returns {Promise<any>}
   */
  getPortalAccess (idEmployee, filter) {
    let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/report/portal-access?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * /api/v2/report/seller/send
   *
   * @param idCustomer
   * @param period
   * @returns {Promise<any>}
   */
  getSellerQuarterSendMail (filter) {
    let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/report/seller/send?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * /api/v2/report/seller/{idEmployee}/month/{year}/{month}
   *
   * @param idCustomer
   * @param period
   * @returns {Promise<any>}
   */
  getStatementCommercialWeekly (year, week, filter) {
    let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/report/statement?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * /api/v2/report/seller/{idEmployee}/month/{year}/{month}
   *
   * @param idCustomer
   * @param period
   * @returns {Promise<any>}
   */
  getReportSellerProcessOpened (filter) {
    let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/report/processes/opened/seller?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * /api/v2/report/alert/update-status
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  updateAlertStatus (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/report/alert/update-status`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * /api/v2/report/alert/add-info
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  addInfoAlert (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/report/alert/add-info`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * /api/v2/report/alert/add-info
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  getInfosAlert (tipoAlerta, idlogisticaHouse) {
    let data = {
      'tipoAlerta': tipoAlerta,
      'idlogisticaHouse': idlogisticaHouse
    }

    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/report/alert/infos`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * /api/v2/search-offer/add-info
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  addSearchOfferInfo (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/search-offer/add-info`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * /api/v2/search-offer/infos
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  getSearchOfferInfos (id) {
    let data = {
      'id': id
    }

    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/search-offer/infos`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * /api/v2/report/alert/update-status
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  historicAlertStatus (data) {
    let queryString = httpBuildQuery(data)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/report/alert-status-historic?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * /api/v2/report/alert/update-status
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  getInternalOperational (data) {
    let queryString = httpBuildQuery(data)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/report/indicators/operational?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * /api/v2/report/seller/insight/{idEmployee}
   *
   * @param idCustomer
   * @param period
   * @returns {Promise<any>}
   */
  getReportSellerInsight (filter) {
    let queryString = httpBuildQuery(
      {
        employee: filter
      })
    // let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/report/seller/insight?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * /api/v2/report/rentabilidade
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  getRentabilidade (data) {
    let queryString = httpBuildQuery(data)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/report/rentabilidade?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  // --------------- Indicators -------------

  /**
   * /api/v2/indicator/commercial
   *
   * @param idCustomer
   * @param period
   * @returns {Promise<any>}
   */
  getIndicatorCommercial (year, week, filter) {
    let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/indicator/commercial?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * /api/v2/indicator/operational
   *
   * @param idCustomer
   * @param period
   * @returns {Promise<any>}
   */
  getIndicatorOperational (year, week, filter) {
    let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/indicator/operational?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getIndicatorProposal (year, week, filter) {
    let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/indicator/proposal?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * /api/v2/report/seller/{idEmployee}/month/{year}/{month}
   *
   * @param idCustomer
   * @param period
   * @returns {Promise<any>}
   */
  getStatementTariffSent (filter) {
    let queryString = httpBuildQuery({
      filter: filter
    })

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/task/tariffs/statement?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * /api/v2/report/alert-proposta/add-info
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  addOfertaFreteInfoAlert (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/report/alert-proposta/add-info`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * /api/v2/report/alert-proposta/add-info
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  getOfertaFreteInfosAlert (tipoAlerta, idofertaFrete) {
    let data = {
      'tipoAlerta': tipoAlerta,
      'idOfertaFrete': idofertaFrete
    }

    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/report/alert-proposta/infos`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * /api/v2/report/alert-proposta/update-status
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  updateOfertaFreteAlertStatus (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/report/alert-proposta/update-status`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * /api/v2/report/alert-proposta-status-historic
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  historicOfertaFreteAlertStatus (data) {
    let queryString = httpBuildQuery(data)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/report/alert-proposta-status-historic?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getIndicatorQtdCotacoes (filter) {
    let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/report/cotacoes-online-qtd-contacoes-periodo?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default ReportService
